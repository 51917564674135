.scroll-icon{
	/*position: absolute;*/
	/*place at bottom of page always*/
 	/*position: fixed; */
	margin-top: 15px;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.mouse{
	width: 24px;
	height: 40px;
	border: 2px solid #fff;
	border-radius: 12px;
	position: relative;
}

.mouse-button{
	width: 4px;
	height: 8px;
	background-color: #fff;
	border-radius: 2px;
	position: absolute;
	top: 8px;
	left: 50%;
	transform: translateX(-50%);
	animation: scroll 2s infinite;
}

@keyframes scroll{
	0% {
		top: 8px;
		opacity: 1;
	}
	50% {
		top: 2px;
		opacity: 0;
	}
	100% {
		top: 8px;
		opacity: 1;
	}
}

.arrow {
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 10px solid #fff;
	margin-top: 10px;
	animation: bounce 2s infinite;
}

@keyframes bounce{
	0%, 100% {
		transform: translateY(0);
	}
	50%{
		transform: translateY(-10px);
	}
}
