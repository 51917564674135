.animated-text {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 20px;
	font-size: 3rem; /* Adjust font size as needed */
  }
  
  .letter {
	display: inline-block;
	margin: 0 5px;
	animation: letter-animation 3s ease-in-out infinite;
  }
  
  @keyframes letter-animation {
	0%, 100% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.2);
	}
  }
  
  /* Customize each letter */
  .letter-0 { transform: rotate(10deg); color: #FF6347; font-family: 'Arial', sans-serif; } /* y */
  .letter-1 { transform: skewX(10deg); color: #FFA500; font-family: 'Courier New', monospace; } /* o */
  .letter-2 { transform: rotate(-10deg); color: #32CD32; font-family: 'Georgia', serif; } /* u */
  .letter-3 { transform: skewY(10deg); color: #1E90FF; font-family: 'Tahoma', sans-serif; } /* n */
  .letter-4 { transform: rotate(15deg); color: #DA70D6; font-family: 'Verdana', sans-serif; } /* i */
  .letter-5 { transform: skewX(-10deg); color: #FFD700; font-family: 'Times New Roman', serif; } /* q */
  .letter-6 { transform: scale(1.1); color: #FF4500; font-family: 'Impact', sans-serif; } /* space */
  .letter-7 { transform: rotate(-15deg); color: #00FA9A; font-family: 'Comic Sans MS', cursive; } /* d */
  .letter-8 { transform: skewY(-10deg); color: #4682B4; font-family: 'Lucida Console', monospace; } /* e */
  .letter-9 { transform: scale(1.2); color: #8A2BE2; font-family: 'Arial', sans-serif; } /* x */
  .letter-10 { transform: rotate(20deg); color: #FF69B4; font-family: 'Courier New', monospace; } /* t */
  .letter-11 { transform: skewX(15deg); color: #ADFF2F; font-family: 'Georgia', serif; } /* e */
  .letter-12 { transform: rotate(-20deg); color: #7B68EE; font-family: 'Tahoma', sans-serif; } /* r */
  .letter-13 { transform: skewY(15deg); color: #FF1493; font-family: 'Verdana', sans-serif; } /* o */
  .letter-14 { transform: scale(1.1); color: #FF8C00; font-family: 'Times New Roman', serif; } /* u */
  .letter-15 { transform: rotate(25deg); color: #00CED1; font-family: 'Impact', sans-serif; } /* s */
  