.animated-name {
	animation: text-animation 3s ease-in-out infinite;
  }
  
  @keyframes text-animation {
	0% {
	  color: #FFFFFF;
	  transform: scale(1);
	}
	50% {
	  color: #FF6347; /* Tomato color */
	  transform: scale(1.2);
	}
	100% {
	  color: #FFFFFF;
	  transform: scale(1);
	}
  }
  
  .custom-cursor {
	position: fixed;
	width: 20px;
	height: 20px;
	background-color: #FF6347;
	border-radius: 50%;
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: transform 0.15s ease-in-out;
	z-index: 9999;
  }
  
  /* Adjust the cursor for smaller screens */
  @media (max-width: 768px) {
	.custom-cursor {
	  width: 15px;
	  height: 15px;
	}
  }
  
  @media (max-width: 480px) {
	.custom-cursor {
	  width: 10px;
	  height: 10px;
	}
  }
  